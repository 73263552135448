<template>
  <div class="radiobutton" :class="classArray">
    <input type="radio" v-model="answer" :value="value" :checked="isChecked" :name="name" :id="id" :required="required" :disabled="disabled" />

    <label :for="id">
      <slot></slot>
    </label>
  </div>
</template>

<script>
export default {
  props: ['modelValue', 'name', 'id', 'flavor', 'size', 'value', 'required', 'disabled'],
  emits: ['update:modelValue'],
  data () {
    return {
      innerId: 'innerId',
    }
  },
  computed: {
    answer: {
      get () {
        return this.modelValue
      },
      set (answer) {
        this.$emit('update:modelValue', answer)
      }
    },
    isChecked () {
      return this.modelValue == this.value
    },
    classArray: function () {
      return [
        'radiobutton--' + this.flavor,
        this.flavor !== 'plain' ? 'radiobutton--custom' : null,
        this.flavor !== 'plain' && this.size == 'sm' ? 'radiobutton--sm' : null,
        this.flavor !== 'plain' && this.size == 'md' ? 'radiobutton--md' : null,
        this.flavor !== 'plain' && this.size == 'lg' ? 'radiobutton--lg' : null,
        this.flavor !== 'plain' && this.size == 'xl' ? 'radiobutton--xl' : null,
        // this.disabled ? 'radiobutton--disabled' : null,
        this.modelValue === this.value ? 'selected' : null
      ]
    }
  }
}
</script>
