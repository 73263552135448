/* eslint-disable no-console */
import {register} from 'register-service-worker'

if (process.env.NODE_ENV === 'production') { // https://levelup.gitconnected.com/vue-pwa-example-298a8ea953c9

  const notifyUserAboutUpdate = function (worker) {
    if (window.confirm('Neue Inhalte sind verfügbar. Jetzt aktualisieren? \nIhre zwischengespeicherten Antworten werden dabei gelöscht.')) {
      // notify the app and refresh sw
      document.dispatchEvent(new CustomEvent('swUpdated', {detail: worker}))
      worker.postMessage({action: 'SKIP_WAITING'})
    }
  }

  register('/service-worker.js', {
    ready() {
      console.log('App is being served from cache by a service worker.\n' + 'For more details, visit https://goo.gl/AFskqB')
    },
    registered() {
      console.log('Service worker has been registered.')
      fetch(process.env.VUE_APP_BASEURL + 'checklists/' + process.env.VUE_APP_KEY + '.json').then(function (response) {
        return response.text();
      }).then(function (data) {
        let regex = /src=\\"([^">]+)/gm;
        let match;
        console.log('data contains', (data.match(regex) || []).length, 'non-empty img-tags');

        while ((match = regex.exec(data)) !== null) {
          let imgSrc = match[1].replaceAll('\\', '')
          console.log(imgSrc);
          fetch(imgSrc, {mode: 'no-cors'})
        }
      })
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated(registration) {
      console.log('New content is available; please refresh.')

      notifyUserAboutUpdate(registration.waiting)
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  })
  // https://levelup.gitconnected.com/vue-pwa-example-298a8ea953c9
  var refreshing = false
  navigator.serviceWorker.addEventListener('controllerchange', function () {
    if (refreshing) 
      return
    
    window.location.href = window.location.origin
    refreshing = true
  })
}
