<template>
  <div class="gearbox" role="presentation">

    <svg v-if="version == 4" class="gearbox-v4" :class="{'gearbox-v4-animation':animation}" role="presentation" viewBox="0 0 300 300" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title></title>
      <defs>
        <path
          :id="'gearbox-v4-path-1' + uid"
          d="M523 1332 c-50 -19 -49 -14 -27 -153 l16 -102 -43 -43 -44 -43 -95
            24 c-132 34 -134 34 -156 -22 -11 -27 -20 -51 -22 -54 -1 -3 40 -37 92 -75
            l96 -69 0 -62 0 -61 -100 -61 -100 -62 20 -51 c10 -27 21 -53 25 -56 3 -3 57
            2 121 12 64 9 117 16 118 14 0 -2 18 -22 40 -45 l38 -41 -22 -98 c-13 -55 -25
            -104 -27 -111 -4 -11 83 -63 105 -63 4 0 39 43 77 95 l69 96 60 -3 61 -3 53
            -85 c29 -47 57 -91 61 -98 6 -10 19 -9 65 9 56 20 58 22 52 53 -3 17 -11 70
            -17 117 l-12 86 43 43 42 42 111 -26 c61 -14 114 -23 118 -19 4 5 16 29 27 55
            l21 48 -95 70 -94 70 0 63 0 63 90 53 c50 29 93 58 96 63 4 6 -2 35 -12 64
            l-18 55 -46 -7 c-25 -4 -76 -12 -115 -19 l-70 -12 -46 44 -46 44 24 97 c12 53
            23 105 23 115 0 17 -67 56 -98 57 -7 0 -42 -40 -79 -90 l-66 -90 -63 0 -62 0
            -59 95 c-34 56 -64 94 -73 94 -8 -1 -34 -9 -57 -17z m320 -436 c163 -70 132
            -319 -44 -351 -73 -13 -161 31 -196 98 -34 66 -14 174 41 220 54 46 136 60
            199 33z" />
      </defs>
      <g class="gear gear-1" transform="matrix(0.24019215,0,0,0.24019215,-33.282075,-25.235351)">
        <use :xlink:href="'#gearbox-v4-path-1' + uid" height="100%" width="100%" y="0" x="0"/>
      </g>
    </svg>

    <svg v-else-if="version == 3" class="gearbox-v3" :class="{'gearbox-v3-animation':animation}" role="presentation" viewBox="0 0 900 300" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title></title>
      <defs>
          <path :id="'gearbox-v3-path-1' + uid" d="m -21.64,-199 -8.125,46.281 0.032,0.031 c -9.932,1.623 
              -19.541,4.224 -28.719,7.688 v -0.031 l -30.156,-36.032 -28.344,16.375 
              16.094,44.125 0.062,0.031 c -7.684,6.308 -14.727,13.376 -21.031,21.063 
              l -0.031,-0.094 -44.125,-16.094 -16.375,28.344 36.031,30.188 c -3.458,9.17 
              -6.036,18.765 -7.656,28.687 l -0.032,-0.031 -46.281,8.125 v 32.75 
              l 46.281,8.125 0.032,-0.031 c 1.62,9.922 4.198,19.517 7.656,28.688 
              l -36.031,30.187 16.375,28.344 44.125,-16.094 0.031,-0.094 c 6.304,7.687 
              13.347,14.755 21.031,21.062 l -0.062,0.031 -16.094,44.125 28.344,16.375 
              30.156,-36.03 v -0.032 c 9.178,3.464 18.787,6.065 28.719,7.688 
              l -0.032,0.031 8.125,46.281 h 32.719 l 8.125,-46.281 -0.031,-0.031 
              c 9.929,-1.619 19.541,-4.198 28.719,-7.656 l 30.187,36.031 28.344,-16.375 
              -16.125,-44.125 c 7.667,-6.292 14.706,-13.335 21,-21 l 44.156,16.094 
              16.344,-28.344 -36,-30.188 c 3.459,-9.172 6.067,-18.764 7.687,-28.687 
              l 46.282,-8.094 v -32.75 l -46.282,-8.125 c -1.621,-9.913 -4.232,-19.494 
              -7.687,-28.656 l 36,-30.188 -16.344,-28.343 -44.156,16.093 c -6.294,-7.665 
              -13.333,-14.708 -21,-21 l 16.125,-44.125 -28.344,-16.375 -30.187,36.031 
              c -9.178,-3.458 -18.79,-6.037 -28.719,-7.656 l 0.031,-0.031 -8.125,-46.281 
              H -21.64 Z m 16.375,79.687 c 63.71,0 115.38,51.634 115.38,115.34 0,63.71 
              -51.665,115.34 -115.38,115.34 -63.71,0 -115.34,-51.634 -115.34,-115.34 
              0,-63.71 51.633,-115.34 115.34,-115.34 z"/>
          <path :id="'gearbox-v3-path-2' + uid" d="M523 1332 c-50 -19 -49 -14 -27 -153 l16 -102 -43 -43 -44 -43 -95
              24 c-132 34 -134 34 -156 -22 -11 -27 -20 -51 -22 -54 -1 -3 40 -37 92 -75
              l96 -69 0 -62 0 -61 -100 -61 -100 -62 20 -51 c10 -27 21 -53 25 -56 3 -3 57
              2 121 12 64 9 117 16 118 14 0 -2 18 -22 40 -45 l38 -41 -22 -98 c-13 -55 -25
              -104 -27 -111 -4 -11 83 -63 105 -63 4 0 39 43 77 95 l69 96 60 -3 61 -3 53
              -85 c29 -47 57 -91 61 -98 6 -10 19 -9 65 9 56 20 58 22 52 53 -3 17 -11 70
              -17 117 l-12 86 43 43 42 42 111 -26 c61 -14 114 -23 118 -19 4 5 16 29 27 55
              l21 48 -95 70 -94 70 0 63 0 63 90 53 c50 29 93 58 96 63 4 6 -2 35 -12 64
              l-18 55 -46 -7 c-25 -4 -76 -12 -115 -19 l-70 -12 -46 44 -46 44 24 97 c12 53
              23 105 23 115 0 17 -67 56 -98 57 -7 0 -42 -40 -79 -90 l-66 -90 -63 0 -62 0
              -59 95 c-34 56 -64 94 -73 94 -8 -1 -34 -9 -57 -17z m320 -436 c163 -70 132
              -319 -44 -351 -73 -13 -161 31 -196 98 -34 66 -14 174 41 220 54 46 136 60
              199 33z"/>
      </defs>
      
      <g class="gear gear-1">
          <use :xlink:href="'#gearbox-v3-path-2' + uid" />
      </g>
      <g class="gear gear-2">
          <use :xlink:href="'#gearbox-v3-path-1' + uid" />
      </g>
      <g class="gear gear-3">
          <use :xlink:href="'#gearbox-v3-path-1' + uid" />
      </g>
    </svg>

    <svg v-else-if="version == 2" class="gearbox-v2" :class="{'gearbox-v2-animation':animation}" role="presentation" viewBox="0 0 900 300" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title></title>
      <defs>
          <path :id="'gearbox-v2-path-1' + uid" d="m -21.64,-199 -8.125,46.281 0.032,0.031 c -9.932,1.623 
              -19.541,4.224 -28.719,7.688 v -0.031 l -30.156,-36.032 -28.344,16.375 
              16.094,44.125 0.062,0.031 c -7.684,6.308 -14.727,13.376 -21.031,21.063 
              l -0.031,-0.094 -44.125,-16.094 -16.375,28.344 36.031,30.188 c -3.458,9.17 
              -6.036,18.765 -7.656,28.687 l -0.032,-0.031 -46.281,8.125 v 32.75 
              l 46.281,8.125 0.032,-0.031 c 1.62,9.922 4.198,19.517 7.656,28.688 
              l -36.031,30.187 16.375,28.344 44.125,-16.094 0.031,-0.094 c 6.304,7.687 
              13.347,14.755 21.031,21.062 l -0.062,0.031 -16.094,44.125 28.344,16.375 
              30.156,-36.03 v -0.032 c 9.178,3.464 18.787,6.065 28.719,7.688 
              l -0.032,0.031 8.125,46.281 h 32.719 l 8.125,-46.281 -0.031,-0.031 
              c 9.929,-1.619 19.541,-4.198 28.719,-7.656 l 30.187,36.031 28.344,-16.375 
              -16.125,-44.125 c 7.667,-6.292 14.706,-13.335 21,-21 l 44.156,16.094 
              16.344,-28.344 -36,-30.188 c 3.459,-9.172 6.067,-18.764 7.687,-28.687 
              l 46.282,-8.094 v -32.75 l -46.282,-8.125 c -1.621,-9.913 -4.232,-19.494 
              -7.687,-28.656 l 36,-30.188 -16.344,-28.343 -44.156,16.093 c -6.294,-7.665 
              -13.333,-14.708 -21,-21 l 16.125,-44.125 -28.344,-16.375 -30.187,36.031 
              c -9.178,-3.458 -18.79,-6.037 -28.719,-7.656 l 0.031,-0.031 -8.125,-46.281 
              H -21.64 Z m 16.375,79.687 c 63.71,0 115.38,51.634 115.38,115.34 0,63.71 
              -51.665,115.34 -115.38,115.34 -63.71,0 -115.34,-51.634 -115.34,-115.34 
              0,-63.71 51.633,-115.34 115.34,-115.34 z"/>
          <path :id="'gearbox-v2-path-2' + uid" d="M523 1332 c-50 -19 -49 -14 -27 -153 l16 -102 -43 -43 -44 -43 -95
              24 c-132 34 -134 34 -156 -22 -11 -27 -20 -51 -22 -54 -1 -3 40 -37 92 -75
              l96 -69 0 -62 0 -61 -100 -61 -100 -62 20 -51 c10 -27 21 -53 25 -56 3 -3 57
              2 121 12 64 9 117 16 118 14 0 -2 18 -22 40 -45 l38 -41 -22 -98 c-13 -55 -25
              -104 -27 -111 -4 -11 83 -63 105 -63 4 0 39 43 77 95 l69 96 60 -3 61 -3 53
              -85 c29 -47 57 -91 61 -98 6 -10 19 -9 65 9 56 20 58 22 52 53 -3 17 -11 70
              -17 117 l-12 86 43 43 42 42 111 -26 c61 -14 114 -23 118 -19 4 5 16 29 27 55
              l21 48 -95 70 -94 70 0 63 0 63 90 53 c50 29 93 58 96 63 4 6 -2 35 -12 64
              l-18 55 -46 -7 c-25 -4 -76 -12 -115 -19 l-70 -12 -46 44 -46 44 24 97 c12 53
              23 105 23 115 0 17 -67 56 -98 57 -7 0 -42 -40 -79 -90 l-66 -90 -63 0 -62 0
              -59 95 c-34 56 -64 94 -73 94 -8 -1 -34 -9 -57 -17z m320 -436 c163 -70 132
              -319 -44 -351 -73 -13 -161 31 -196 98 -34 66 -14 174 41 220 54 46 136 60
              199 33z"/>
      </defs>
      
      <g class="gear gear-1">
          <use :xlink:href="'#gearbox-v2-path-1' + uid" />
      </g>
      <g class="gear gear-2">
          <use :xlink:href="'#gearbox-v2-path-1' + uid" />
      </g>
      <g class="gear gear-3">
          <use :xlink:href="'#gearbox-v2-path-2' + uid" />
      </g>
    </svg>

    <svg v-else class="gearbox-v1" :class="{'gearbox-v1-animation':animation}" role="presentation" viewBox="0 0 900 300" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title></title>
      <defs>
          <path :id="'gearbox-v1-path-1' + uid" d="m -21.64,-199 -8.125,46.281 0.032,0.031 c -9.932,1.623 
              -19.541,4.224 -28.719,7.688 v -0.031 l -30.156,-36.032 -28.344,16.375 
              16.094,44.125 0.062,0.031 c -7.684,6.308 -14.727,13.376 -21.031,21.063 
              l -0.031,-0.094 -44.125,-16.094 -16.375,28.344 36.031,30.188 c -3.458,9.17 
              -6.036,18.765 -7.656,28.687 l -0.032,-0.031 -46.281,8.125 v 32.75 
              l 46.281,8.125 0.032,-0.031 c 1.62,9.922 4.198,19.517 7.656,28.688 
              l -36.031,30.187 16.375,28.344 44.125,-16.094 0.031,-0.094 c 6.304,7.687 
              13.347,14.755 21.031,21.062 l -0.062,0.031 -16.094,44.125 28.344,16.375 
              30.156,-36.03 v -0.032 c 9.178,3.464 18.787,6.065 28.719,7.688 
              l -0.032,0.031 8.125,46.281 h 32.719 l 8.125,-46.281 -0.031,-0.031 
              c 9.929,-1.619 19.541,-4.198 28.719,-7.656 l 30.187,36.031 28.344,-16.375 
              -16.125,-44.125 c 7.667,-6.292 14.706,-13.335 21,-21 l 44.156,16.094 
              16.344,-28.344 -36,-30.188 c 3.459,-9.172 6.067,-18.764 7.687,-28.687 
              l 46.282,-8.094 v -32.75 l -46.282,-8.125 c -1.621,-9.913 -4.232,-19.494 
              -7.687,-28.656 l 36,-30.188 -16.344,-28.343 -44.156,16.093 c -6.294,-7.665 
              -13.333,-14.708 -21,-21 l 16.125,-44.125 -28.344,-16.375 -30.187,36.031 
              c -9.178,-3.458 -18.79,-6.037 -28.719,-7.656 l 0.031,-0.031 -8.125,-46.281 
              H -21.64 Z m 16.375,79.687 c 63.71,0 115.38,51.634 115.38,115.34 0,63.71 
              -51.665,115.34 -115.38,115.34 -63.71,0 -115.34,-51.634 -115.34,-115.34 
              0,-63.71 51.633,-115.34 115.34,-115.34 z"/>
      </defs>
      
      <g class="gear gear-1">
          <use :xlink:href="'#gearbox-v1-path-1' + uid" />
      </g>
      <g class="gear gear-2">
          <use :xlink:href="'#gearbox-v1-path-1' + uid" />
      </g>
      <g class="gear gear-3">
          <use :xlink:href="'#gearbox-v1-path-1' + uid" />
      </g>
    </svg>

    <span class="sr-only">
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
    name: 'Gearbox',
    props: {
        version: Number,
        animation: Boolean,
    },
    data () {
      return {
        uid: String
      }
    },
     
    mounted () {
      this.uid = this._.uid
    }
}
</script>

<style>
</style>
